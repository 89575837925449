import React from 'react';
import Layout from '../components/Layout';
import TitleAndTheme from '../components/TitleAndTheme';
import ProjectHeader from '../components/ProjectHeader';
import Grid, { GridThreeAcross } from '../components/Grid';
import AssetWithCaption from '../components/AssetWithCaption';
import Text from '../components/Text';

const Featdrake = () => (
  <Layout>
    <TitleAndTheme
      title="Edwin Morris, Feat. Drake, Future Of What band website"
      themeClass="theme--featdrake"
    />

    <ProjectHeader name="Feat. Drake" subhead="Soundcloud circa 1985" />

    <Text>
      <p>
        Future Of What is a band based in Brooklyn, operating within their own
        collective called Feat. Drake Records. My Genius co-worker Max is in the
        band, and I made this website over a weekend in December 2014 for their
        album{' '}
        <em>
          <a
            target="_blank"
            href="https://open.spotify.com/album/33lCy8j495O6I1MIaJr6MC"
            rel="noopener noreferrer"
          >
            Pro Dreams
          </a>
        </em>
        .
      </p>
    </Text>

    <Grid verticalMargin="bottom">
      <AssetWithCaption
        position="full"
        youTubeVideo="Bt7vfJ6l7mc"
        width="1600"
        height="1000"
        hasSound
      />
    </Grid>

    <GridThreeAcross>
      <AssetWithCaption
        position="left_third"
        video="featdrake/featdrakemobile1"
        width="750"
        height="1335"
      />

      <AssetWithCaption
        position="center_third"
        video="featdrake/featdrakemobile2"
        width="750"
        height="1335"
      />

      <AssetWithCaption
        position="right_third"
        video="featdrake/featdrakemobile3"
        width="750"
        height="1335"
      />
    </GridThreeAcross>

    <Text>
      <p>
        Max sent me a few old videos of a car and a SoundCloud playlist. I loved
        the warm analog feeling of the footage, and the blurred out monospace
        type, so I made a walkman-inspired audio player to fit the retro theme,
        and reproduced the type with CSS.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="full"
        video="featdrake/textblurring_fast"
        width="3360"
        height="420"
      />
    </Grid>

    <Text>
      <p>
        The text marquee uses a small Mozilla open source project called
        MiniDaemon, which allows an interval to be played and paused. Since the
        text itself is being changed, the same animation can be played in the
        tab title bar.
      </p>
    </Text>

    <Grid>
      <AssetWithCaption
        position="full"
        video="featdrake/featdraketab"
        width="560"
        height="70"
      />
    </Grid>

    <Text noBottomMargin>
      <p>
        If you like their music, you can find Future Of What{' '}
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://soundcloud.com/futureofwhat"
        >
          on SoundCloud
        </a>
        .
      </p>
    </Text>
  </Layout>
);

export default Featdrake;
